import React from "react";
import {Helmet} from "react-helmet";
import './who-we-are.component.scss';

const WhoWeAre = () => {

    return (
        <>
            <Helmet>
                <title>Who We Are | The Digital Agency</title>
                <meta name={"description"} content={"The Digital Agency has grown to become one of the London leading agency, specialising in digital marketing, web and mobile development"} />
            </Helmet>

            <div className={"parent-container"}>
                <div className={"container"}>
                    <div  className="row">
                        <div  className="col-md-10 offset-md-1">
                            <div  className="card">
                                <div  className="card-body p-5 text-center">
                                    <h1 className="py-2 sub-heading">Get To Know Us</h1>
                                    <h3  className="pb-2 sub-tagline">We Deliver Growth</h3>

                                    <p> Since 2008, we have been helping our clients
                                    grow through our unparalleled services. We work shoulder to shoulder with our
                                    clients so that we can build creative and strategic solutions to their
                                    challenges. </p>

                                    <p className="pb-2"> The Digital Agency offers
                                    a comprehensive approach with the speed and agility you need to propel your business
                                    forward. Our research-based strategy means we kick-off our work with you by
                                    immersing ourselves in your industry, your vision and your culture. We take care of
                                    your creative needs so that you can focus on running your business; we work together
                                    in order to help you grow. </p>

                                    <a  className="btn btn-primary text-dark"
                                        href="/contact-us"> Get in Touch </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WhoWeAre;
