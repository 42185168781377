import React from 'react';
import HeaderComponent from "./components/header/header.component";
import FooterComponent from "./components/footer/footer.component";
import {Route, Switch, withRouter} from "react-router-dom";
import Home from "./pages/home/home.component";
import ContactUs from "./pages/contactus/contactus.component";
import WhoWeAre from "./pages/who-we-are/who-we-are.component";
import OurClients from "./pages/our-clients/our-clients.component";


function App() {
  return (
    <div className="page-container">
        {/*todo*/}
        {/* <title>{title}</title>
            <meta charSet="utf-8" /> */}

        <header>
            <HeaderComponent />
        </header>

        <main>
            <Switch>
                <Route path={"/"}  component={Home} exact/>
                <Route path={"/contact-us"}  component={ContactUs} exact/>
                <Route path={"/who-we-are"}  component={WhoWeAre} exact/>
                <Route path={"/our-clients"}  component={OurClients} exact/>
            </Switch>
        </main>

        <footer>
            <FooterComponent />
        </footer>
    </div>
  );
}

export default withRouter(App);
