import React from 'react';
import BannerText from "../../components/banner-text/banner-text.component";
import './our-clients.component.scss';
import {Helmet} from "react-helmet";

const OurClients =() => {
    return(
        <>
            <Helmet>
                <title>Our Clients | The Digital Agency</title>
                <meta name={"description"} content={"We are proud to work with world renowned clients in banking, travel, retail, insurance and technology industry"} />
            </Helmet>
            <div className={"parent-container1"}>
                <div className={"container"}>
                    <BannerText/>
                </div>
            </div>
            <div className={"container content-container"}>

                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/lloyds.jpg" alt={"Lloyds Banking Group"} aria-label={"Lloyds Banking Group"} />
                        <div className={"client-name"}>
                            <a href={"https://www.lloydsbank.com/business/corporate-banking/home.html"} aria-label={"Lloyds Banking Group"}>Lloyds Commercial</a>
                        </div>
                    </div>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/hsbc.jpg" alt={"HSBC Banking"} aria-label={"HSBC Banking"} />
                        <div className={"client-name"}>
                            <a href={"https://www.hsbc.co.uk/"} aria-label={"HSBC Banking"}>HSBC</a>
                        </div>
                    </div>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/sainsburys.jpg" alt={"Sainsburys Bank"} aria-label={"Sainsburys Bank"} />
                        <div className={"client-name"}>
                            <a href={"https://www.sainsburysbank.co.uk/"} aria-label={"Sainsbury Bank"}>Sainsburys Bank</a>
                        </div>
                    </div>
                </div>


                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/asos.jpg" alt={"ASOS"} aria-label={"ASOS"} />
                        <div className={"client-name"}>
                            <a href={"https://www.asos.com/"} aria-label={"ASOS"}>ASOS</a>
                        </div>
                    </div>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/cadbury.jpg" alt={"Cadbury"} aria-label={"Cadbury"} />
                        <div className={"client-name"}>
                            <a href={"https://www.cadbury.co.uk/"} aria-label={"Cadbury"}>Cadburys</a>
                        </div>
                    </div>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/dominos.jpg" alt={"Dominos"} aria-label={"Dominos"} />
                        <div className={"client-name"}>
                            <a href={"https://www.dominos.co.uk/"} aria-label={"Dominos"}>Dominos</a>
                        </div>
                    </div>
                </div>


                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/booking.com.jpg" alt={"Booking.com"} aria-label={"Booking.com"} />
                        <div className={"client-name"}>
                            <a href={"https://www.booking.com/index.en-gb.html"} aria-label={"Booking.com"}>Booking.com</a>
                        </div>
                    </div>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/manutd.jpg" alt={"Manchester United"} aria-label={"Manchester United"} />
                        <div className={"client-name"}>
                            <a href={"https://www.manutd.com/"} aria-label={"Manchester United"}>Man Utd</a>
                        </div>
                    </div>
                    <div className={"col-md-4"}>
                        <img src="/assets/img/clients/zuto.jpg" alt={"Zuto"} aria-label={"Zuto"} />
                        <div className={"client-name"}>
                            <a href={"https://www.zuto.com/"} aria-label={"Zuto"}>Zuto</a>
                        </div>
                    </div>
                </div>

                <div className={"row pb-4 mb-4 mt-2"}>
                    <div className={"col-12 text-center"}>
                        And more end clients in banking, insurance, retail, logistics, medical, education, food and sports industry.
                    </div>
                </div>

            </div>
        </>
    )
}

export default OurClients;
