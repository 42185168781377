import React, {useRef} from 'react'
import './header.component.scss'
import {Nav, Navbar} from "react-bootstrap";
import { NavLink } from 'react-router-dom';

type Homeprops = {

}
const  HeaderComponent: React.FC<Homeprops> = () => {
    //const { row } = style;
    const telRef = useRef(null);
    const FA = require('react-fontawesome');

//    function copyTel(e) {
//        telRef.current.select();
//        document.execCommand('copy');
//        e.target.focus();
//        console.log(navigator.clipboard.readText());
//    }

    return(
        <>
            <div className="container header-container">
                <div className={"row no-gutters"}>
                    <div className="col-md-9 logo-container">
                        <div className={"container-item"}>
                            <NavLink to={"/"}>
                                <img className="logo-img" src="../../../assets/img/logo-vh.png" alt="The Digital Agency Logo" />
                            </NavLink>
                        </div>
                        <div className={"container-item pl-4"}>
                            <div className="brand-text">The Digital Agency</div>
                            <div className="brand-tagline">Creative with technology experts</div>
                        </div>
                    </div>
                    <div className="col-md-3 d-none d-md-block">
                        <div className="header-contacts">
                            <a href="mailto:contact@thedigitalagency.london">contact@thedigital.uk</a><br />
                            {/*todo*/}
                            <a href={'/#'} ref={telRef}>0208 133 3306</a>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Navbar collapseOnSelect expand="md">
                    <div className={"container"}>
                        {/*<div className={"d-block d-md-none"}>*/}
                            {/*<Navbar.Brand href="/">The Digital Agency: </Navbar.Brand>*/}
                        {/*</div>*/}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            {/*<Nav.Link title={"What we do"} href="#features">What we do</Nav.Link>*/}
                            {/*<Nav.Link title={"Our clients"} href="#pricing">Our clients</Nav.Link>*/}
                            {/*<Nav.Link title={"Who we are"} href="#pricing">Who we are</Nav.Link>*/}
                            {/*<Nav.Link title={"Contact us"} href="/contact-us">Contact us</Nav.Link>*/}

                            <NavLink to={"/"}>
                                <Nav.Link title={"What we do"} href="/#">What we do</Nav.Link>
                            </NavLink>
                            <NavLink to={"/our-clients"}>
                                <Nav.Link title={"Our clients"} href="/our-clients">Our clients</Nav.Link>
                            </NavLink>
                            <NavLink to={"/who-we-are"}>
                                <Nav.Link title={"Who we are"} href="/who-we-are">Who we are</Nav.Link>
                            </NavLink>
                            <NavLink to={"/contact-us"}>
                                <Nav.Link title={"Contact us"} href="/contact-us">Contact us</Nav.Link>
                            </NavLink>

                            {/*<NavDropdown title="Dropdown" id="collasible-nav-dropdown">*/}
                                {/*<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                                {/*<NavDropdown.Divider />*/}
                                {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
                            {/*</NavDropdown>*/}
                        </Nav>
                        <Nav>
                            {/*<Nav.Link eventKey={2} href="#memes">*/}
                                {/*Dank memes*/}
                            {/*</Nav.Link>*/}
                            <div className={"social-media-icons d-none d-md-block"}>
                                <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/digitalagency_london/" title={"Instagram link for TheDigitalAgency"}>
                                    <FA name="instagram" />
                                </a>
                                <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/thedigitalagencylondon/" title={"Facebook link for TheDigitalAgency"}>
                                    <FA name="facebook-square" />
                                </a>
                                <a target={"_blank"} rel="noreferrer" href="https://twitter.com/_Digital_Agency" title={"Twitter link for TheDigitalAgency"}>
                                    <FA name="twitter" />
                                </a>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    </div>
                </Navbar>
                {/*<div className="footer-line-sep"></div>*/}
            </div>
        </>
    )
}

export default HeaderComponent
