import * as React from "react";
import {Service} from "../../models/service.model";
import './content-grid.component.scss';

type ContentGridProps = {
    items: Service[]
}


const ContentGrid: React.FC<ContentGridProps> = ({items = []}) => {
    return (
        <div className={"content-grid-container row"}>
            {items.map((item) => {
                return (
                    <div key={item.id} className={"col-xl-6 col-lg-6 col-md-12"}>
                        <div className={"content-container"}>
                            <div className={"content-img"}>
                                <a href={"/" + item.link} title={item.heading}>
                                <img className={"grid-img"} alt={item.heading} src={"/assets/img/" + item.imageURL} />
                                </a>
                            </div>
                            <div className={"content-heading"}>
                                <a href={"/" + item.link} title={item.heading} >{item.heading}</a>
                            </div>
                            <div className={"content-sub-heading"}>
                                { item.subHeading }
                            </div>
                            <div className={"content-description"}>
                                { item.description }
                            </div>
                            {/*<div className={"content-link"}>*/}
                                {/*<a href={"/" + item.link} title={item.heading} >{item.heading}</a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default ContentGrid;
