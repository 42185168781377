import React from "react";
import {Helmet} from "react-helmet";
import './contactus.component.scss';

const ContactUs = () => {

    const FA = require('react-fontawesome');

    return (
        <>
            <Helmet>
                <title>Contact Us | The Digital Agency</title>
                <meta name={"description"} content={"Get in touch to discuss your projects, we can help you achieve your ambitious goals into reality"} />
            </Helmet>
            <div className={"parent-container1"}>
                <div className={"container"}>
                    <div className={"row no-gutters"}>
                        <div className={"col-lg-6 d-none d-md-block"}>
                            <img aria-hidden src="/assets/img/contact.png" alt="Contact Us image" />
                        </div>
                        <div className={"col-lg-6 p-4"}>
                            <div className={"main-title"}>
                                Connect with Us
                            </div>
                            <div className={"sub-title"}>
                                Let’s Collaborate
                            </div>
                            <div className={"pb-4"}>
                                Get in touch to learn more about how we can drive your brand forward.
                                As a full-service Creative Agency, we know how to help you and your
                                business grow and flourish. All you’ve got to do is call!
                            </div>
                            <div className={"pb-2"}>
                                Address: 4 Old Park Lane, Mayfair, London, W1K 1QW
                            </div>
                            <div className={"email-address pb-2"}>
                                Email:
                                <a className={"pl-2"} href={"mailto:contact@thedigital.uk"} title="Email: contact@thedigital.uk">
                                    contact@thedigital.uk
                                </a>
                            </div>
                            <div className={"pb-4"}>
                                Tel.: 0208 133 3306
                            </div>
                            <div className={"social-media-icons"}>
                                <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/digitalagency_london/" title={"Instagram link for TheDigitalAgency"}>
                                    <FA name="instagram" />
                                </a>
                                <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/thedigitalagencylondon/" title={"Facebook link for TheDigitalAgency"}>
                                    <FA name="facebook-square" />
                                </a>
                                <a target={"_blank"} rel="noreferrer" href="https://twitter.com/_Digital_Agency" title={"Twitter link for TheDigitalAgency"}>
                                    <FA name="twitter" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 py-5">
                        <iframe title={"The Digital Agency Location Google Map"} className={"b-0"} width="100%" height="450"
                                src="https://www.google.com/maps/embed/v1/search?q=4%20Old%20Park%20Lane%2C%20London%2C%20United%20Kingdom&amp;key=AIzaSyDnIJA_fxtrKNfRiZFyvbVvJFs2nR47CWA"
                                frameBorder="0" allowFullScreen={false}></iframe>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;
