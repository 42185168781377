import './banner-text.component.scss';

const BannerText = () => {
    return(

        <div className={"banner-text-container"}>
            <div className={"container"}>
                <div className={"text-title"}>Services We Offer</div>
                <div className={"text-sub-title"}>Beautiful, engaging digital experiences</div>
                <div className={"text-block"}>
                    Not every Creative Agency is created equal.
                    At The Digital Agency, we know that the best results come from having
                    the right people working on the right project. Our team offers expertise
                    in various areas of Creative Agency services, which is why each client
                    is matched with a suitable group of experts to help them achieve their goals.
                    With our proven strategies, your business is bound for wild success.
                </div>
            </div>
        </div>
    )}

    export default BannerText;
