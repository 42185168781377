import React from "react";
import BannerText from "../../components/banner-text/banner-text.component";
import {serviceData} from "../../data/services-data";
import ContentGrid from "../../components/content/content-grid.component";
import {Helmet} from "react-helmet";
import './home.component.scss';


const Home = () => {
    return (
        <>
        <Helmet>
            <title>The Digital | Result Driven Creative Digital Agency</title>
            <meta name={"description"} content={"Creative agency based in London providing clients with niche digital marketing and development services in web and mobile arena"} />
            {/*<link rel="canonical" href="http://mysite.com/example" />*/}
        </Helmet>
        <div className={"video-container"}>
            <video playsInline={true} autoPlay muted loop>
                <source src="../../../assets/img/tda-video.mp4" type="video/mp4"/>
                <source src="../../../assets/img/tda-video.webm" type="video/webm"/>
            </video>
        </div>

        <div className = "video-text w-100 text-white mt-5" >
            <h1 className = "display-4 homepage-heading" > Move Forward with<br/> The Digital Agency </ h1>
            <h6 className = "mb-0 homepage-sub-heading"> Expert Strategists Who Deliver</h6>
        </div>

        <BannerText />
        <ContentGrid items = {serviceData}/>
    </>
    );
}

export default Home;
