import React from 'react';
import './footer.component.scss';

const FA = require('react-fontawesome');

const FooterComponent = () => {
    return(
      <div className={"col-12 p-0"}>
          <div className="footer-line-sep"></div>
          <div className="footer-content">
              <a href={"mailto:contact@thedigital.uk"} >
                  contact@thedigital.uk
              </a><br />
              0208 133 3306<br />
              4 Old Park Lane, Mayfair, London, W1K 1QW<br/>

                <div className={"social-media-icons"}>
                    <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/digitalagency_london/" title={"Instagram link for TheDigitalAgency"}>
                        <FA name="instagram" />
                    </a>
                    <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/thedigitalagencylondon/" title={"Facebook link for TheDigitalAgency"}>
                        <FA name="facebook-square" />
                    </a>
                    <a target={"_blank"} rel="noreferrer" href="https://twitter.com/_Digital_Agency" title={"Twitter link for TheDigitalAgency"}>
                        <FA name="twitter" />
                    </a>
                </div>
              <div className="copyright">©2021 by The Digital Agency</div>
          </div>
      </div>
    );
}

export default FooterComponent;
